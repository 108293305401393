export default {
	install: (app) => {
		app.component('brand-logo', require('@/components/custom/logo/BrandLogo').default)
		app.component('brand-name', require('@/components/custom/logo/BrandName').default)
		app.component('icon-component', require('@/components/icons/IconComponent').default)
		app.component('select-component', require('@/components/custom/select/SelectComponent').default)
		app.component('form-row', require('@/components/elements/formRow').default)
		app.component('ordinary-button', require('@/components/elements/ordinaryButton').default)
	}
}
