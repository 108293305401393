import axios from 'axios'

const authStore = {
	store: {
		token: null
	},

	getters: {
		GET_TOKEN: (state) => {
			return state.token
		}
	},

	actions: {
		async getCSRFToken () {
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'sanctum/csrf-cookie', {
					withCredentials: true,
				})
				.then((response) => {
					console.log(response)
				})
		},

		async signIn (context, data) {
			let result = 'PENDING';
			const { mail, password, isRemembered } = data;
			console.log(isRemembered)
			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'auth/token', {
					email: mail,
					password: password,
				}, {
					withCredentials: true,
				})
				.then((response) => {
					if (response.data?.['access_token']) {
						if (isRemembered) {
							let date = new Date(Date.now() + 86400e3)
							date = date.toUTCString()
							document.cookie = `access_token=${response.data?.['access_token']}; path=/; expires=${date}`
						} else {
							document.cookie = `access_token=${response.data?.['access_token']}; path=/`
						}
						context.commit('SET_TOKEN', response.data?.['access_token'])
						result = 'SUCCESS'
					} else {
						result = 'ERROR'
					}
				})
				.catch(() => {
					result = 'ERROR'
				})
			return result
		},

		checkCookie (context) {
			const cookieStart = document.cookie.indexOf('access_token=') + 13
			const cookieEnd = document.cookie.indexOf(';', cookieStart) !== -1 ? document.cookie.indexOf(';', cookieStart)
				: document.cookie.length
			context.commit('SET_TOKEN', document.cookie.substring(cookieStart, cookieEnd) || null)
		}
	},

	mutations: {
		SET_TOKEN (state, payload) {
			state.token = payload
		},
	}
}

export default authStore
