import { createRouter, createWebHistory } from 'vue-router'
// import authStore from '@/store/modules/authStore.js'

const authChildRoutes = (prefix) => [
	{
		path: 'enter',
		name: prefix + '.enter',
		meta: { auth: false, redirect: true, name: 'Войти' },
		component: () => import('@/views/auth/SignIn.vue')
	}
]
const defaultChildRoutes = (prefix) => [
	{
		path: '',
		name: prefix + '.PageListModule',
		meta: { auth: true, name: 'Все модули' },
		component: () => import('@/views/modules/PageListModule.vue')
	},
	{
		path: 'lineList',
		name: prefix + '.lineList',
		meta: { auth: true, name: 'Все линии' },
		component: () => import('@/views/lines/lineList.vue')
	},
	{
		path: 'moduleTypeList',
		name: prefix + '.moduleTypeList',
		meta: { auth: true, name: 'Все типы модулей' },
		component: () => import('@/views/moduleTypes/moduleTypeList.vue')
	},
	{
		path: 'clientsList',
		name: prefix + '.clientsList',
		meta: { auth: true, name: 'Все клиенты' },
		component: () => import('@/views/clients/clientsList.vue')
	},
	{
		path: 'eventsList',
		name: prefix + '.eventsList',
		meta: { auth: true, name: 'Все события' },
		component: () => import('@/views/events/eventsList.vue')
	},
	{
		path: '/modulePage/:id',
		name: prefix + '.modulePage',
		meta: {
			auth: true,
			name: 'Модули'
		},
		component: () => import('@/views/modules/modulePage.vue')
	},
	{
		path: '/moduleLogsPage/:id',
		name: prefix + '.moduleLogsPage',
		meta: {
			auth: true,
			name: 'Логи модуля'
		},
		component: () => import('@/views/modules/moduleLogsPage.vue')
	},
	{
		path: 'PageAddModule',
		name: prefix + '.PageAddModule',
		meta: { auth: true, name: 'Новый модуль' },
		component: () => import('@/views/modules/PageAddModule.vue')
	},
	{
		path: 'linePage/:id',
		name: prefix + '.linePage',
		meta: { auth: true, name: 'Линии' },
		component: () => import('@/views/lines/linePage.vue')
	},
	{
		path: 'PageAddLine',
		name: prefix + '.PageAddLine',
		meta: { auth: true, name: 'Новая Линия' },
		component: () => import('@/views/lines/PageAddLine.vue')
	},
	{
		path: 'eventsPage/:id',
		name: prefix + '.eventsPage',
		meta: { auth: true, name: 'События' },
		component: () => import('@/views/events/eventsPage.vue')
	},
	{
		path: 'eventsAddNew',
		name: prefix + '.eventsAddNew',
		meta: { auth: true, name: 'Добавить событие' },
		component: () => import('@/views/events/eventsAddNew.vue')
	},
	{
		path: 'PageStatisticModule/:id',
		name: prefix + '.PageStatisticModule',
		meta: { auth: true, name: 'Статистика' },
		component: () => import('@/views/modules/PageStatisticModule.vue')
	},
	{
		path: 'clientPage/:id',
		name: prefix + '.clientPage',
		meta: { auth: true, name: 'Клиенты' },
		component: () => import('@/views/clients/clientPage.vue')
	},
	{
		path: 'clientsAddNew',
		name: prefix + '.clientsAddNew',
		meta: { auth: true, name: 'Новый клиент' },
		component: () => import('@/views/clients/clientsAddNew.vue')
	},
	{
		path: 'moduleTypePage/:id',
		name: prefix + '.moduleTypePage',
		meta: { auth: true, name: 'Типы модулей' },
		component: () => import('@/views/moduleTypes/moduleTypePage.vue')
	},
	{
		path: 'moduleTypeAddNew',
		name: prefix + '.moduleTypeAddNew',
		meta: { auth: true, name: 'Тип модуля' },
		component: () => import('@/views/moduleTypes/moduleTypeAddNew.vue')
	},
	{
		path: 'PageStatisticLine/:id',
		name: prefix + '.PageStatisticLine',
		meta: { auth: true, name: 'Статистика' },
		component: () => import('@/views/lines/PageStatisticLine.vue')
	}
]

const routes = [
	{
		path: '',
		name: 'dashboard',
		component: () => import('../layouts/DefaultLayout.vue'),
		children: defaultChildRoutes('default')
	},
	{
		path: '/auth',
		name: 'auth',
		component: () => import('../layouts/BlankLayout.vue'),
		children: authChildRoutes('auth')
	},
	{
		path: '/404',
		component: () => import('@/views/errors/errorPage404.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/404'
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	base: process.env.BASE_URL,
	routes
})

const DEFAULT_TITLE = 'Aurora NXT - '

router.afterEach((to) => {
	document.title = DEFAULT_TITLE + (to.meta.name || DEFAULT_TITLE)
})

router.beforeEach(async(to, from, next) => {
	const store = await import('@/store')
	store.default.dispatch('checkCookie')

	let match = document.cookie.match(RegExp('(?:^|;\\s*)access_token=([^;]*)'))

	if (to.matched.some(record => record.meta.auth) && match === null) {
		next({ name: 'auth.enter' })
		return
	}
	next()
})

export default router
