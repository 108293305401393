import axios from 'axios'

const modulesStore = {
	state: () => ({
		modulesList: [],
		currentModule: [],
		statuses: [],
		moduleLogs: null
	}),

	getters: {
		GET_MODULES: (state) => {
			return state.modulesList
		},
		GET_MODULE: (state) => {
			return state.currentModule
		},
		GET_STATUSES: (state) => {
			return state.statuses
		},
		GET_MODULELOGS: (state) => {
			return state.moduleLogs
		}
	},

	actions: {
		async getModules (context, params) {
			let result = 'ERROR'

			const { take, skip } = params || {}

			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'module', {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					},
					take: take || null,
					skip: skip || null
				})
				.then((response) => {
					if (!response?.data.errorCode) {
						result = 'SUCCESS'
						context.commit('SET_MODULES', response?.data || [])
					} else {
						result = 'ERROR'
					}
				})

			return result
		},

		async getModuleByID (context, id) {
			let result = 'ERROR'
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'module/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response?.data?.id) {
						result = 'SUCCESS'
						context.commit('SET_MODULE', response?.data || [])
					}
				})
				.catch((error) => {
					console.log(error)
					result = 'ERROR'
				})
			return result
		},

		async getModuleLogs (context, id) {
			let result = 'ERROR'
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'module/logs/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					console.log(response)
					if (response?.data) {
						result = 'SUCCESS'
						context.commit('SET_MODULELOGS', response?.data || [])
					}
				})
				.catch((error) => {
					console.log(error)
					result = 'ERROR'
				})
			return result
		},

		async getStatuses (context) {
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'module/statuses', {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					context.commit('SET_STATUSES', response?.data || [])
				})
		},

		async saveModule (context, data) {

			const form = new FormData()

			for (let key in data) {
				form.append(key, data[key])
			}

			if (data.photos) {
				form.append('photos[]', data.photos)
			}

			if (data.documents && data.documents.length) {
				for (let i = 0; i < data.documents.length; i++) {
					form.append('documents[]', data.documents[i])
				}
			}

			let result = 'ERROR'
			let errorCode = null

			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'module/' + data.id, form, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response?.data?.id) {
						result = 'SUCCESS'
						context.commit('SET_MODULE', response.data)
					} else {
						errorCode = response?.data?.errorCode
					}
				})
			return { result, errorCode }
		},

		async saveModuleNew (context, data) {
			const form = new FormData()

			for (let key in data) {
				form.append(key, data[key])
			}

			if (data.photos) {
				form.append('photos[]', data.photos)
			}

			if (data.documents) {
				for (let i = 0; i < data.documents.length; i++) {
					form.append('documents[]', data.documents[i])
				}
			}

			let result = 'ERROR'
			let errorCode = null

			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'module', form, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response?.data?.id) {
						result = 'SUCCESS'
					} else {
						errorCode = response?.data?.errorCode
					}
				})
			return { result, errorCode }
		},

		async deleteModule (context, id) {
			let result = 'ERROR'
			await axios
				.delete(process.env.VUE_APP_SERVER_URL + 'module/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (!response?.data?.errorCode) {
						result = 'SUCCESS'
					}
				})
			return result
		}
	},

	mutations: {
		SET_MODULES (state, payload) {
			state.modulesList = payload
		},
		SET_MODULE (state, payload) {
			state.currentModule = payload
		},
		SET_STATUSES (state, payload) {
			state.statuses = payload
		},
		SET_MODULELOGS (state, payload) {
			state.moduleLogs = payload
		}
	}
}

export default modulesStore
