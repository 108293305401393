<template>
	<div class="flex-table__row normal">
		<div class="flex-table__item col-3 text-dark">
			{{ title }}
		</div>
		<div v-if="isSlot" class="flex-table__item col-6">
			<slot />
		</div>
		<div v-else class="flex-table__item col-6">
			{{ label }}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			default: ''
		},
		label: {
			type: String,
			default: ''
		},
		isSlot: {
			type: Boolean,
			default: false
		}
	}
}
</script>

<style lang="scss" scoped>
	.flex-table__row {

		&.normal {
			height: 74px;
		}
	}
</style>
