import axios from 'axios'

const typeStore = {
	state: () => ({
		typeList: [],
		moduleType: null,
	}),

	getters: {
		GET_TYPELIST: (store) => {
			return store.typeList;
		},
		GET_MODULETYPE: (store) => {
			return store.moduleType;
		}
	},

	actions: {
		async getTypes (context, params) {
			const { take, skip } = params || {}
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'type', {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					},
					take: take || null,
					skip: skip || null
				})
				.then((response) => {
					if (response?.data) {
						context.commit('SET_TYPELIST', response?.data || [])
					}
				})
		},

		async getModuleType (context, id) {
			let result = 'ERROR'

			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'type/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					console.log(response, 'response')
					if (response?.data?.id) {
						context.commit('SET_MODULETYPE', response?.data || [])
						result = 'SUCCESS'
					}
				})
			return result
		},

		async deleteType (context, id) {
			let result = 'ERROR'
			await axios
				.delete(process.env.VUE_APP_SERVER_URL + 'type/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response.data) {
						result = 'SUCCESS'
					}
				})
			return result
		},

		async saveType (context, params) {
			let result = 'ERROR'
			const { documents, id } = params
			const form = new FormData()

			for (let key in params) {
				form.append(key, params[key])
			}

			if (params.type_events && params.type_events.length) {
				params.type_events.map((item, index) => {
					form.append(`event_ids[${index}]`, item)
				})
			}

			if (params.key_events && params.key_events.length) {
				params.key_events.map((item, index) => {
					form.append(`key_event_ids[${index}]`, item)
				})
			}

			if (documents) {
				for (let i = 0; i < documents.length; i++) {
					form.append('documents[]', documents[i]);
				}
			}

			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'type/' + id, form, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if ( response?.data?.id ) {
						context.commit('SET_MODULETYPE', response.data)
						result = 'SUCCESS'
					}
				})
			return result
		},

		async saveNewType (context, params) {
			let result = 'ERROR'
			const { documents } = params
			const form = new FormData()

			for (let key in params) {
				form.append(key, params[key])
			}

			if (documents) {
				for (let i = 0; i < documents.length; i++) {
					form.append('documents[]', documents[i]);
				}
			}

			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'type', form, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if ( !response?.data?.errorCode ) {
						context.commit('SET_MODULETYPE', response.data || null)
						result = 'SUCCESS'
					}
				})
			return result
		},
	},

	mutations: {
		SET_TYPELIST (state, payload) {
			state.typeList = payload;
		},
		SET_MODULETYPE (state, payload) {
			state.moduleType = payload;
		}
	},
}

export default typeStore;
