import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue3-apexcharts'
import BootstrapVueNext from 'bootstrap-vue-next'
import CounterUp from 'vue3-autocounter'
import Notifications from '@kyvg/vue3-notification'
import globalComponent from './plugins/global-components'
import globalDirective from './plugins/global-directive'
import globalMixin from './plugins/global-mixin'
import FloatingVue from 'floating-vue'
import mitt from 'mitt'

import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

const emitter = mitt()

require('waypoints/lib/noframework.waypoints.min')

const app = createApp(App)
app.use(store).use(router)

app.provide('emitter', emitter);

app.use(VueSweetalert2)
app.use(VueApexCharts)
app.use(BootstrapVueNext)
app.use(Notifications)

app.use(FloatingVue)

import 'floating-vue/dist/style.css'

app.component('counter-up', CounterUp)

app.use(globalComponent)
app.use(globalDirective)

app.mixin(globalMixin)

app.mount('#app')

export default app
