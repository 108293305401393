import axios from 'axios'

const eventStore = {
	state: () => ({
		moduleEvents: [],
		currentEvent: null,
		conditionTypes: [],
		params: []
	}),

	getters: {
		GET_DEFINEDEVENTS: (store) => {
			return store.moduleEvents
		},
		GET_CURRENTEVENT: (store) => {
			return store.currentEvent
		},
		GET_CONDITIONTYPES: (store) => {
			return store.conditionTypes
		},
		GET_PARAMS: (store) => {
			return store.params
		},
	},

	actions: {
		async getDefinedEvents (context) {
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'event/definition', {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response?.data) {
						context.commit('SET_DEFINEDEVENTS', response?.data || [])
					}
				}).catch((error) => {
					console.log(error, 'ERROR')
				})
		},

		async getOneEventDefinition (context, id) {
			let result = 'ERROR'

			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'event/definition/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response?.data?.id) {
						result = 'SUCCESS'
						context.commit('SET_CURRENTEVENT', response?.data || null)
					}
				})
			return result
		},

		async deleteEvent (context, id) {
			let result = 'ERROR'
			await axios
				.delete(process.env.VUE_APP_SERVER_URL + 'event/definition/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (!response?.data?.errorCode) {
						result = 'SUCCESS'
					}
				})
			return result
		},

		async getDefinitionTypes (context) {
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'event/definition/types', {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if(!response?.data?.errorCode) {
						context.commit('SET_CONDITIONTYPES', response.data)
					}
				})
		},

		async saveEventDefinition (context, params) {
			let result = 'ERROR'
			const { id } = params
			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'event/definition/' + id,
				{
					...params
				},
				{
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response?.data?.id) {
						context.commit('SET_CURRENTEVENT', response?.data || [])
						result = 'SUCCESS'
					}
				})
			return result
		},

		async saveEventDefinitionNew (context, params) {
			let result = 'ERROR'

			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'event/definition', {
					...params
				},
				{
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (!response?.data?.errorCode) {
						result = 'SUCCESS'
					}
				})
			return result
		},

		async getParams (context) {
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'event/definition/parameters', {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response?.data) {
						context.commit('SET_PARAMS', response?.data || [])
					}
				}).catch((error) => {
					console.log(error, 'ERROR')
				})
		},
	},

	mutations: {
		SET_DEFINEDEVENTS (state, payload) {
			state.moduleEvents = payload
		},
		SET_CURRENTEVENT (state, payload) {
			state.currentEvent = payload
		},
		SET_CONDITIONTYPES (state, payload) {
			state.conditionTypes = payload
		},
		SET_PARAMS (state, payload) {
			state.params = payload
		},
	},
}

export default eventStore;
