<template>
	<!--Logo start-->
	<div class="logo-main">
		<div class="logo-normal">
			<img :src="require(`@/assets/images/aurora/logo.svg`)" alt="" />
		</div>
		<div class="logo-mini">
			<img :src="require(`@/assets/images/aurora/logo.svg`)" alt="" />
		</div>
	</div>
	<!--Logo End-->
</template>

<script>
export default {
	props: {
		color: { type: Boolean, default: false }
	}
}
</script>
