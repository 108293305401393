<template>
	<button
		role="button"
		class="btn btn-primary text-center"
		@click="$emit('save')"
	>
		{{ label }}
	</button>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: ''
		}
	}
}
</script>
