import axios from 'axios'

const overalEventsStore = {
	state: () => ({
		allModuleEvents: {
			events: []
		},
		allLineEvents: {
			events: []
		}
	}),

	getters: {
		GET_MODULEEVENTS: (state) => {
			return state.allModuleEvents
		},
		GET_LINEEVENTS: (state) => {
			return state.allLineEvents
		},
	},

	actions: {
		async getModuleEvents (context, params) {
			const { id, start, end, event_types } = params || {}
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'event/module/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					},
					params: {
						start,
						end,
						event_types
					}
				})
				.then((response) => {
					context.commit('SET_MODULEEVENTS', response?.data || [])
				})
		},

		async getLineEvents (context, params) {
			const { id, start, end, event_types } = params || {}
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'event/line/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					},
					params: {
						start,
						end,
						event_types
					}
				})
				.then((response) => {
					context.commit('SET_LINEEVENTS', response?.data || [])
				})
		},
	},

	mutations: {
		SET_MODULEEVENTS (state, payload) {
			state.allModuleEvents = payload;
		},
		SET_LINEEVENTS (state, payload) {
			state.allLineEvents = payload;
		},
	},
}

export default overalEventsStore;
