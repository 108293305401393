<template>
	<vue-select
		ref="select"
		v-model="newValue"
		:options="options"
		:clear-on-select="false"
		label="name"
		:trackBy="trackBy"
		:placeholder="placeholder"
		:searchable="isSearchable"
		:max="selectNumber"
		:closeOnSelect="(selectNumber === -1) ? true : false"
		:mode="mode"
		:loading="!(options.length > 0)"
	>
		<slot></slot>
	</vue-select>
</template>

<script>
import { ref, watch, onMounted, computed } from 'vue'
import VueSelect from '@vueform/multiselect'

export default {
	components: {
		VueSelect
	},
	props: {
		modelValue: {
			type: null,
			required: true
		},
		placeholder: {
			type: String,
			default: 'Select'
		},
		options: {
			type: Array,
			required: true,
			default: () => []
		},
		mode: {
			type: String,
			default: 'single'
		},
		selectNumber: {
			type: Number,
			default: -1
		},
		trackBy: {
			type: String,
			default: 'value'
		},
		isSearchable: {
			type: Boolean,
			default: true
		}
	},

	emits: ['updateValue'],

	setup(props, { emit }) {
		const newValue = ref(null)
		const select = ref(null)

		const getModelValue = computed(() => {
			return props.modelValue
		})

		watch(getModelValue, () => {
			newValue.value = props.modelValue
		})

		watch(newValue, () => {
			if (props.selectNumber !== -1 && newValue.value && props.selectNumber === newValue.value.length) {
				select.value.close()
			}
			emit('updateValue', newValue.value)
		})

		onMounted(() => {
			newValue.value = props.modelValue
		})

		return {
			newValue,
			select
		}
	}
}
</script>

<style>
@import '@vueform/multiselect/themes/default.css';
.multiselect-option.is-selected {
	background: var(--bs-primary) !important;
}
.multiselect-option.is-selected.is-pointed {
	background: var(--bs-primary) !important;
}
.multiselect.is-active {
	box-shadow: unset !important;
}
.multiselect.is-open {
	border-radius: unset;
}
.multiselect-option.is-pointed {
	background: var(--bs-primary);
	color: white;
}
.multiselect-tag {
	background: var(--bs-primary);
}
.multiselect-tag-remove-icon {
	color: white !important;
}
.multiselect {
	display: flex;
}
</style>
