import axios from 'axios'

const clientsStore = {
	state: () => ({
		clientsList: [],
		currentClient: {}
	}),

	getters: {
		GET_CLIENTS: (state) => {
			return state.clientsList
		},
		GET_CLIENT: (state) => {
			return state.currentClient
		},
	},

	actions: {
		async getClients (context, params) {
			const { take, skip } = params || {}
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'client', {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					},
					take: take || null,
					skip: skip || null
				})
				.then((response) => {
					context.commit('SET_CLIENTS', response?.data || [])
				})
		},

		async getClientByID (context, id) {
			let result = 'ERROR'

			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'client/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response?.data?.id) {
						context.commit('SET_CLIENT', response?.data || [])
						result = 'SUCCESS'
					}
				})
			return result
		},

		async saveClient (context, data) {
			let result = 'ERROR'
			const { id } = data
			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'client/' + id, {
					...data
				},
	{
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response?.data?.id) {
						result = 'SUCCESS'
					}
				})

			return result
		},

		async saveClientNew (context, data) {
			let result = 'ERROR'

			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'client',
					{
						...data
					},
					{
						headers: {
							Authorization: 'Bearer ' + context.rootState.authStore.token
						}
					})
				.then((response) => {
					if (response?.data?.id) {
						result = 'SUCCESS'
					}
				})

			return result
		},

		async deleteClient (context, id) {
			let result = 'ERROR'
			await axios
				.delete(process.env.VUE_APP_SERVER_URL + 'client/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (!response?.data?.errorCode) {
						result = 'SUCCESS'
					}
				})
			return result
		}
	},

	mutations: {
		SET_CLIENTS (state, payload) {
			state.clientsList = payload;
		},
		SET_CLIENT (state, payload) {
			state.currentClient = payload;
		},
	},
}

export default clientsStore;
