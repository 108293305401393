<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
		<path fill="#fff" d="M0 0h20v20H0z" />
		<path d="M9.9575 12.1167c-2.87497 0-5.30076.4698-5.30076 2.2829 0 1.8139 2.44147 2.2672 5.30076 2.2672 2.875 0 5.3008-.4698 5.3008-2.2829 0-1.8139-2.4415-2.2672-5.3008-2.2672Z" />
		<path d="M9.95742 10.3891c1.94678 0 3.50778-1.56993 3.50778-3.52792 0-1.95871-1.561-3.52793-3.50778-3.52793-1.94673 0-3.50771 1.56922-3.50771 3.52793 0 1.95799 1.56098 3.52792 3.50771 3.52792Zm7.61598-2.70641c.5036-1.98122-.973-3.76057-2.8535-3.76057-.2044 0-.3999.02252-.5909.06079-.0254.006-.0537.01876-.0686.04128-.0172.02851-.0045.06679.0141.09155.5649.797.8894 1.76735.8894 2.80899 0 .99811-.2976 1.92869-.82 2.70094-.0537.0795-.006.18683.0888.20333.1313.02325.2657.03525.403.039 1.3692.03609 2.5982-.85025 2.9377-2.18531Z" opacity=".4" />
		<path d="M19.0078 12.3474c-.2507-.5373-.8558-.9058-1.7759-1.0867-.4343-.1066-1.6094-.2566-2.7026-.2364-.0164.0023-.0253.0135-.0268.021-.0023.0105.0022.0285.0238.0398.5052.2514 2.4579 1.3448 2.2124 3.651-.0104.0998.0694.1861.1686.1711.4806-.0691 1.717-.3362 2.1005-1.1685.2119-.4397.2119-.9508 0-1.3913Z" />
		<path d="M5.87058 3.98315c-.19028-.03903-.38652-.06079-.59097-.06079-1.88035 0-3.35702 1.77935-2.85261 3.76057.33877 1.33511 1.56771 2.22136 2.93693 2.18536.13729-.00375.27235-.0165.40293-.039.09476-.0165.14252-.12384.08879-.20342-.52232-.773-.82004-1.70279-.82004-2.70091 0-1.04239.32533-2.01274.89018-2.80898.01791-.02477.03134-.06304.01343-.09156-.01492-.02326-.04253-.03527-.06864-.04127Z" opacity=".4" />
		<path d="M2.76813 11.2606c-.92003.1808-1.52443.5493-1.775142 1.0867-.212656.4405-.212656.9516 0 1.3921.383532.8315 1.619932 1.0995 2.100462 1.1677.09925.015.17834-.0706.1679-.1711-.2455-2.3054 1.70723-3.3988 2.21313-3.6502.02089-.012.02537-.0294.02313-.0406-.00149-.0075-.0097-.0188-.02611-.0203-1.09389-.021-2.26835.1291-2.70337.2357Z" />
	</svg>
</template>
