<template>
	<router-view />
</template>

<script>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useRouter } from 'vue-router'
import '@/plugins/styles'

export default {
	name: 'App',

	setup() {
		const store = useStore()
		const route = useRoute()
		const router = useRouter()

		onMounted(() => {
			store.dispatch('getCSRFToken');
			store.dispatch('checkCookie').then(() => {
				if (store.getters.GET_TOKEN === null && route.path !== '/auth/enter') {
					router.push({ name: 'auth.enter' })
				}
				if (store.getters.GET_TOKEN !== null && route.path == '/auth/enter') {
					router.push({ name: 'default.PageListModule' })
				}
			})
		})
	}
}
</script>

<style lang="scss">
@import '@/assets/custom-vue/scss/styles.scss';
</style>
