import axios from 'axios'

const linesStore = {
	state: () => ({
		linesList: [],
		lineStatuses: [],
		currentLine: []
	}),

	getters: {
		GET_LINES: (state) => {
			return state.linesList
		},
		GET_LINESTATUSES: (state) => {
			return state.lineStatuses
		},
		GET_CURRENTLINE: (state) => {
			return state.currentLine
		}
	},

	actions: {
		async getLines (context, params) {
			const { take, skip } = params || {};
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'line', {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					},
					take: take || null,
					skip: skip || null
				})
				.then((response) => {
					context.commit('SET_LINES', response?.data || [])
				})
		},

		async getLineById (context, id) {
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'line/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					context.commit('SET_CURRENTLINE', response?.data || [])
				})
		},

		async deleteLine (context, id) {
			let result = 'ERROR'
			await axios
				.delete(process.env.VUE_APP_SERVER_URL + 'line/' + id, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response.data) {
						result = 'SUCCESS'
					}
				})
			return result
		},

		async getLineStatuses (context) {
			await axios
				.get(process.env.VUE_APP_SERVER_URL + 'line/statuses', {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					context.commit('SET_LINESTATUSES', response?.data || [])
				})
		},

		async saveLine (context, data) {
			let result = 'ERROR'
			const { id } = data
			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'line/' + id, {
					...data
				}, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response.data) {
						result = 'SUCCESS'
					}
				})
			return result
		},

		async saveLineNew (context, data) {
			let result = 'ERROR'
			await axios
				.post(process.env.VUE_APP_SERVER_URL + 'line', {
					...data
				}, {
					headers: {
						Authorization: 'Bearer ' + context.rootState.authStore.token
					}
				})
				.then((response) => {
					if (response.data) {
						result = 'SUCCESS'
					}
				})
			return result
		},
	},

	mutations: {
		SET_LINES (state, payload) {
			state.linesList = payload;
		},
		SET_LINESTATUSES (state, payload) {
			state.lineStatuses = payload;
		},
		SET_CURRENTLINE (state, payload) {
			state.currentLine = payload;
		},
	},
}

export default linesStore;
