<template>
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" viewBox="0 0 20 20">
		<g clip-path="url(#a)">
			<path opacity="0.4" d="m8.14279 4.21971-5.259 5.17159 1.02756 6.21 7.75135 2.3678 7.0365-3.4624-2.2785-9.35969-8.27791-.9273Z" />
			<path stroke="#fff" d="M15.5082 19.4876c2.1486 0 3.8907-1.7419 3.8907-3.8907 0-2.1487-1.7421-3.8906-3.8907-3.8906-2.1489 0-3.8906 1.7419-3.8906 3.8906 0 .1311.0065.2607.0192.3885l-1.74269-.5256h-.00007c-.63324-.191-1.30125.1676-1.49222.8007-.19097.6331.16712 1.3014.80071 1.4925h.00006l4.97031 1.4993c.4171.1523.8669.2353 1.3353.2353Zm1.7687-7.9507c.6408-.1624 1.0295-.8138.8665-1.4554l-1.6426-6.48403C16.1295 1.83536 14.5665.512451 12.6937.512451c-1.2439 0-2.3515.584219-3.06292 1.491239L6.17835 5.53433c-.00003.00004-.00007.00007-.0001.0001-.46287.4732-.45348 1.2314.019 1.69352.4728.46246 1.2311.45402 1.69342-.01895.00002-.00003.00005-.00006.00008-.00009l1.2316-1.25961c.5981 1.3795 1.97165 2.34445 3.57135 2.34445.8736 0 1.6805-.28838 2.3299-.77451l.7983 3.15086c.1624.6412.814 1.0293 1.455.8668Z" />
			<circle cx="12.6937" cy="4.40318" r="1.98228" fill="#fff" />
			<circle cx="15.5086" cy="15.597" r="1.98228" fill="#fff" />
			<circle cx="12.6934" cy="4.40309" r="1.0005" opacity="0.4" />
			<circle cx="5.07716" cy="12.3438" r=".668958" />
			<path fill="#fff" d="M4.35675 8.95946c.37894-.44204 1.06281-.44204 1.44176 0l.51927.60572c.1408.16425.33348.2755.54612.31531l.78422.14681c.57228.1072.91422.6994.72087 1.2486l-.26494.7526c-.07184.2041-.07184.4265 0 .6306l.26494.7526c.19335.5492-.14859 1.1414-.72087 1.2486l-.78422.1468c-.21264.0398-.40532.1511-.54612.3153l-.51927.6057c-.37895.4421-1.06282.4421-1.44176 0l-.51927-.6057c-.14081-.1642-.33348-.2755-.54613-.3153l-.78421-.1468c-.57229-.1072-.91422-.6994-.72088-1.2486l.26494-.7526c.07184-.2041.07184-.4265 0-.6306l-.26494-.7526c-.19334-.5492.1486-1.1414.72088-1.2486l.78421-.14681c.21265-.03981.40532-.15106.54613-.31531l.51927-.60572Z" />
			<path stroke="#fff" d="m7.07243 15.9207-.03377-.1804.03377.1804h.00027-.00027Zm-.41373.5448.47723-.5567.72071-.1349c1.25516-.235 2.00512-1.534 1.58107-2.7385l-.24349-.6917.24349-.6916c.42405-1.2045-.3259-2.50346-1.58107-2.73847l-.72071-.13495-.47723-.55669c-.83111-.96949-2.33102-.96949-3.16214 0l-.47722.55669-.72072.13495C1.04346 9.14864.293502 10.4476.717552 11.6521l.243489.6917-.243489.6916c-.42405 1.2045.325908 2.5035 1.581068 2.7385l.72072.1349.47722.5567c.83112.9695 2.33103.9695 3.16214 0ZM5.07763 9.85933l.37996.44317c.30882.3603.73141.6043 1.1978.6916l.57381.1074-.19386.5507c-.15756.4476-.15756.9355 0 1.3831l.19386.5507-.57381.1074c-.46639.0873-.88898.3313-1.1978.6916l-.37996.4432-.37996-.4432c-.30882-.3603-.7314-.6043-1.19779-.6916l-.57382-.1074.19386-.5507c.15757-.4476.15757-.9355 0-1.3831l-.19386-.5507.57382-.1074c.46639-.0873.88897-.3313 1.19779-.6916l.37996-.44317Zm.1391-.16226.00021-.00025-.00021.00025Z" />
			<circle cx="15.5088" cy="15.5969" r=".998547" opacity="0.4" />
			<circle cx="5.07765" cy="12.3438" r="1.27003" opacity="0.4" />
			<path fill="#fff" d="m5.69966 7.17539-.58105-.03991-.27388-.34754.78629-.3419.06864.72935ZM18.5356 12.2062l-.5811-.04-.2738-.3475.7863-.3419.0686.7294ZM7.69319 15.9099l.54688.545-.06142.6311-1.12448-.287.63902-.8891Z" />
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h20v20H0z" />
			</clipPath>
		</defs>
	</svg>
</template>
