import { createStore, createLogger } from 'vuex'
import setting from './setting'
import authStore from './modules/authStore.js';
import modulesStore from './modules/modulesStore.js';
import linesStore from './modules/linesStore.js';
import clientsStore from './modules/clientsStore.js';
import typeStore from './modules/typeStore.js';
import eventStore from './modules/eventStore.js';
import overalEventsStore from './modules/overalEventsStore.js';

const debug = process.env.NODE_ENV !== 'production'
export default createStore({
	modules: {
		authStore: authStore,
		modulesStore: modulesStore,
		typeStore: typeStore,
		linesStore: linesStore,
		clientsStore: clientsStore,
		eventStore: eventStore,
		overalEventsStore: overalEventsStore,
		setting: setting
	},
	state: {
		lang: 'ru',
		signInPopup: false,
		signUpPopup: false,
		shareOffcanvas: false
	},
	mutations: {
		langChangeCommit(state, payload) {
			state.lang = payload
		},
		openModalCommit(state, payload) {
			state[payload.name] = payload.value
		},
		openBottomCanvasCommit(state, payload) {
			state[payload.name] = payload.value
		}
	},
	actions: {
		openModalAction({ commit }, payload) {
			commit('openModalCommit', payload)
		},
		openBottomCanvasAction({ commit }, payload) {
			commit('openBottomCanvasCommit', payload)
		},
		langChangeAction(context, payload) {
			context.commit('langChangeCommit', payload)
		}
	},
	strict: debug,
	plugins: debug ? [createLogger()] : []
})
